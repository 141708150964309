// Library Utilities
import React, { useEffect } from "react";
import Select from "react-select";

// Components
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";

// Styles
import Styles from "./passportServicePopup.module.scss";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import dropDownStyles from "../../../../../Shared/dropDownStyles";

// constants
import {
  days,
  months,
  expiryYears,
  countryList,
  passportList,
} from "../../../../../constants/data";
import { MEDIUM } from "../../../../../Shared/buttonSize";

// Functions
import {
  validName,
  validPassportNumber,
} from "../../../../../utils/validation";
import { getYears } from "../../../../../utils/getYears";
import { IdsGenerator } from "../../../../../utils/idGenerator";

function PassportServicePopup({ toastTimeout, handleResponse, name }: any) {
  const errorObject = {
    firstNameError: false,
    lastNameError: false,
    dobDayError: false,
    dobMonthError: false,
    dobYearError: false,
    genderError: false,
    expiryDayError: false,
    expiryMonthError: false,
    expiryYearError: false,
    passportNumberError: false,
    countryError: false,
    typeError: false,
    consentError: false,
  };

  const firstNameRef = React.useRef<any>("");
  const lastNameRef = React.useRef<any>("");
  const dateRef = React.useRef<any>();
  const monthRef = React.useRef<any>();
  const yearRef = React.useRef<any>();
  const expiryDateRef = React.useRef<any>();
  const expiryMonthRef = React.useRef<any>();
  const expiryYearRef = React.useRef<any>();
  const countryRef = React.useRef<any>();
  const passportNumberRef = React.useRef<any>("");
  const typeRef = React.useRef<any>();

  const [day, setDay] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const [expiryDay, setExpiryDay] = React.useState("");
  const [expiryMonth, setExpiryMonth] = React.useState("");
  const [expiryYear, setExpiryYear] = React.useState("");
  const [countryName, setCountryName] = React.useState("");
  const [typeName, setTypeName] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const [error, setError] = React.useState(errorObject);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [selectedGender, setSelectedGender] = React.useState("");

  // checking first name valid or not on change
  function checkFirstName(forRunButton: boolean = false) {
    const fName = firstNameRef.current.value.toUpperCase();
    const isFirstNameValid = validName(fName) ? true : false;
    if (forRunButton) {
      return isFirstNameValid;
    } else {
      setError({ ...error, firstNameError: !isFirstNameValid });
    }
  }

  const yearOptions = getYears(1950);

  // checking last name valid or not on change
  function checkLastName(forRunButton: boolean = false) {
    const lName = lastNameRef.current.value.toUpperCase();
    const isLastNameValid = validName(lName) ? true : false;
    if (forRunButton) {
      return isLastNameValid;
    } else {
      setError({ ...error, lastNameError: !isLastNameValid });
    }
  }

  // checking passport number valid or not on change
  function checkPassportNumber(forRunButton: boolean = false) {
    const pNumber = passportNumberRef.current.value.toUpperCase();
    const isPassportNumberValid = validPassportNumber(pNumber) ? true : false;
    if (forRunButton) {
      return isPassportNumberValid;
    } else {
      setError({ ...error, passportNumberError: !isPassportNumberValid });
    }
  }

  // conditions to check Run verfication button should be enabled or disabled
  useEffect(() => {
    try {
      if (
        !checkFirstName(true) ||
        !checkLastName(true) ||
        !checkPassportNumber(true) ||
        error.firstNameError ||
        error.lastNameError ||
        error.passportNumberError ||
        !day ||
        !month ||
        !year ||
        !expiryDay ||
        !expiryMonth ||
        !expiryYear ||
        error.dobDayError ||
        error.dobMonthError ||
        error.dobYearError ||
        error.expiryDayError ||
        error.expiryMonthError ||
        error.expiryYearError ||
        !selectedGender ||
        error.genderError ||
        !countryName ||
        error.countryError ||
        !typeName ||
        error.typeError
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } catch (err) {
      setIsDisabled(true);
      toastTimeout(true, "error", (err as Error).message);
    }
  }, [error]);

  function handleCheck() {
    const firstName = firstNameRef.current.value.toUpperCase();
    const lastName = lastNameRef.current.value.toUpperCase();
    const date = `${day}/${month}/${year}`;
    const expiryDate = `${expiryDay}/${expiryMonth}/${expiryYear}`;
    const gender = !selectedGender ? "" : selectedGender === "Male" ? "M" : "F";
    const passportNumber = validPassportNumber(
      passportNumberRef.current.value.toUpperCase()
    )
      ? passportNumberRef.current.value.toUpperCase()
      : "NA";
    const consent = isChecked === true ? "Y" : "N";

    if (!checkFirstName(true)) {
      setError((error) => ({
        ...error,
        firstNameError: true,
      }));
      return false;
    } else if (!checkLastName(true)) {
      setError((error) => ({
        ...error,
        lastNameError: true,
      }));
      return false;
    } else if (!checkPassportNumber(true)) {
      setError((error) => ({
        ...error,
        passportNumberError: true,
      }));
      return false;
    } else if (!day) {
      setError((error) => ({
        ...error,
        dobDayError: true,
      }));
      return false;
    } else if (!month) {
      setError((error) => ({
        ...error,
        dobMonthError: true,
      }));
      return false;
    } else if (!year) {
      setError((error) => ({
        ...error,
        dobYearError: true,
      }));
      return false;
    } else if (!expiryDay) {
      setError((error) => ({
        ...error,
        expiryDayError: true,
      }));
      return false;
    } else if (!expiryMonth) {
      setError((error) => ({
        ...error,
        expiryMonthError: true,
      }));
      return false;
    } else if (!expiryYear) {
      setError((error) => ({
        ...error,
        expiryYearError: true,
      }));
      return false;
    } else if (!typeName) {
      setError((error) => ({
        ...error,
        typeError: true,
      }));
      return false;
    } else if (!countryName) {
      setError((error) => ({
        ...error,
        countryError: true,
      }));
      return false;
    } else if (!gender) {
      setError((error) => ({
        ...error,
        consentError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError((error) => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    handleResponse(
      {
        firstName,
        lastName,
        dob: date,
        expiryDate,
        country: countryName,
        gender,
        passportNumber,
        type: typeName,
        consent,
      },
      setLoading
    );
  }

  return (
    <div className={`${Styles.Content}`}>
      <div className="d-flex justify-content-between">
        <div>
          <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
          <div className={`${Styles.headingText} pl-2`}>{name}</div>
        </div>
      </div>

      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="d-flex flex-grow-1 flex-column justify-content-between">
              <div className={`${Styles.inputContent} d-flex flex-column`}>
                <label className={`${Styles.label}`}>First Name*</label>
                <InputField
                  id={IdsGenerator({
                    prefix: "passport service popup",
                    id: "change",
                    sufix: "first name",
                  })}
                  onChange={checkFirstName}
                  inputRef={firstNameRef}
                  isError={error.firstNameError}
                  purpose="forPassport"
                />
                {error.firstNameError && (
                  <ErrorMessage>First Name field is invalid</ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>DOB</label>
                <div className="d-flex mr-1">
                  <div className="mr-2 d-flex">
                    <div className="d-flex flex-column">
                      <Select
                        id={IdsGenerator({
                          prefix: "passport service popup",
                          id: "select",
                          sufix: "date",
                        })}
                        ref={dateRef}
                        onChange={(e: any) => {
                          setDay(e.value);
                          setError({ ...error, dobDayError: false });
                        }}
                        options={days}
                        styles={
                          !error.dobDayError
                            ? dropDownStyles.customStylesForDays
                            : dropDownStyles.customStylesForDaysError
                        }
                        placeholder="DD"
                      />
                    </div>
                    <div className="ml-2 d-flex flex-column">
                      <Select
                        id={IdsGenerator({
                          prefix: "passport service popup",
                          id: "select",
                          sufix: "month",
                        })}
                        onChange={(e: any) => {
                          setMonth(e.value);
                          setError({ ...error, dobMonthError: false });
                        }}
                        ref={monthRef}
                        options={months}
                        styles={
                          !error.dobMonthError
                            ? dropDownStyles.customStylesForMonths
                            : dropDownStyles.customStylesForMonthsError
                        }
                        placeholder="MMM"
                      />
                    </div>
                    <div className="ml-2 d-flex flex-column">
                      <Select
                        id={IdsGenerator({
                          prefix: "passport service popup",
                          id: "select",
                          sufix: "year",
                        })}
                        onChange={(e: any) => {
                          setYear(e.value);
                          setError({ ...error, dobYearError: false });
                        }}
                        ref={yearRef}
                        placeholder="YYYY"
                        options={yearOptions}
                        styles={
                          !error.dobYearError
                            ? dropDownStyles.customStylesForYears
                            : dropDownStyles.customStylesForYearsError
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>Passport Number*</label>
                <InputField
                  id={IdsGenerator({
                    prefix: "passport service popup",
                    id: "change",
                    sufix: "passport number",
                  })}
                  onChange={checkPassportNumber}
                  inputRef={passportNumberRef}
                  isError={error.passportNumberError}
                  purpose="forPassport"
                />
                {error.passportNumberError && (
                  <ErrorMessage>
                    Please enter a valid passport number
                  </ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>Passport Type</label>
                <Select
                  id={IdsGenerator({
                    prefix: "passport service popup",
                    id: "select",
                    sufix: "passport type",
                  })}
                  onChange={(e: any) => {
                    setTypeName(e.value);
                    setError({ ...error, typeError: false });
                  }}
                  placeholder=""
                  ref={typeRef}
                  options={passportList}
                  styles={
                    !error.typeError
                      ? dropDownStyles.customStylesForCountry
                      : dropDownStyles.customStylesForCountryError
                  }
                />
                {error.typeError && (
                  <ErrorMessage>Please select type!</ErrorMessage>
                )}
              </div>
            </div>

            <div className="d-flex flex-column flex-grow-1">
              <div className={`${Styles.inputContent} d-flex flex-column`}>
                <label className={`${Styles.label} ml-2`}>Last Name*</label>
                <div className="ml-2">
                  <InputField
                    id={IdsGenerator({
                      prefix: "passport service popup",
                      id: "change",
                      sufix: "last name",
                    })}
                    onChange={checkLastName}
                    inputRef={lastNameRef}
                    isError={error.lastNameError}
                    purpose="forPassport"
                  />
                </div>
                {error.lastNameError && (
                  <ErrorMessage>Last Name field is invalid</ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label} ml-2`}>Gender*</label>
                <div className={`${Styles.genderSelector} d-flex ml-2`}>
                  <input
                    id={IdsGenerator({
                      prefix: "passport service popup",
                      id: "select female",
                      sufix: "gender",
                    })}
                    required
                    type="radio"
                    name="gender"
                    value="Female"
                    onChange={(e) => {
                      setSelectedGender(e.target.value);
                      setError((prevError) => ({
                        ...prevError,
                        genderError: false,
                      }));
                    }}
                  />
                  <label
                    className="mr-2"
                    htmlFor={IdsGenerator({
                      prefix: "passport service popup",
                      id: "select female",
                      sufix: "gender",
                    })}
                  >
                    Female
                  </label>

                  <input
                    id={IdsGenerator({
                      prefix: "passport service popup",
                      id: "select male",
                      sufix: "gender",
                    })}
                    required
                    type="radio"
                    name="gender"
                    value="Male"
                    onChange={(e) => {
                      setSelectedGender(e.target.value);
                      setError((prevError) => ({
                        ...prevError,
                        genderError: false,
                      }));
                    }}
                  />
                  <label
                    htmlFor={IdsGenerator({
                      prefix: "passport service popup",
                      id: "select male",
                      sufix: "gender",
                    })}
                  >
                    Male
                  </label>
                </div>
                {error.genderError && (
                  <ErrorMessage>Please select gender!</ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex flex-column`}>
                <label className={`${Styles.label} ml-2`}>
                  Passport Expiry Date
                </label>
                <div className="d-flex mr-1 ml-2">
                  <div className="mr-2 d-flex">
                    <div className="d-flex flex-column">
                      <Select
                        id={IdsGenerator({
                          prefix: "passport service popup",
                          id: "select expiry",
                          sufix: "date",
                        })}
                        onChange={(e: any) => {
                          setExpiryDay(e.value);
                          setError({ ...error, expiryDayError: false });
                        }}
                        ref={expiryDateRef}
                        options={days}
                        styles={
                          !error.expiryDayError
                            ? dropDownStyles.customStylesForDays
                            : dropDownStyles.customStylesForDaysError
                        }
                        placeholder="DD"
                      />
                    </div>
                    <div className="ml-2 d-flex flex-colmun">
                      <Select
                        id={IdsGenerator({
                          prefix: "passport service popup",
                          id: "select expiry",
                          sufix: "month",
                        })}
                        onChange={(e: any) => {
                          setExpiryMonth(e.value);
                          setError({ ...error, expiryMonthError: false });
                        }}
                        ref={expiryMonthRef}
                        options={months}
                        styles={
                          !error.expiryMonthError
                            ? dropDownStyles.customStylesForMonths
                            : dropDownStyles.customStylesForMonthsError
                        }
                        placeholder="MMM"
                      />
                    </div>
                    <div className="ml-2 d-flex flex-column">
                      <Select
                        id={IdsGenerator({
                          prefix: "passport service popup",
                          id: "select expiry",
                          sufix: "year",
                        })}
                        onChange={(e: any) => {
                          setExpiryYear(e.value);
                          setError({ ...error, expiryYearError: false });
                        }}
                        ref={expiryYearRef}
                        placeholder="YYYY"
                        options={expiryYears}
                        styles={
                          !error.expiryYearError
                            ? dropDownStyles.customStylesForYears
                            : dropDownStyles.customStylesForYearsError
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${Styles.inputContent} d-flex mt-2 flex-column ml-2`}
              >
                <label className={`${Styles.label} ml-1`}>Country</label>
                <Select
                  id={IdsGenerator({
                    prefix: "passport service popup",
                    id: "select",
                    sufix: "country",
                  })}
                  onChange={(e: any) => {
                    setCountryName(e.value);
                    setError({ ...error, countryError: false });
                  }}
                  ref={countryRef}
                  options={countryList}
                  styles={
                    !error.countryError
                      ? dropDownStyles.customStylesForCountry
                      : dropDownStyles.customStylesForCountryError
                  }
                  placeholder=""
                />
              </div>
              {error.countryError && (
                <ErrorMessage>Please select country!</ErrorMessage>
              )}
            </div>
          </div>
          <div className="pt-4 pl-4 pb-4 d-flex flex-column">
            <input
              id={IdsGenerator({
                prefix: "passport service popup",
                id: "check",
                sufix: "consent",
              })}
              required
              type="checkbox"
              className="form-check-input"
              onChange={(e: any) => setIsChecked(e.target.checked)}
              style={error.consentError ? { border: "1px solid red" } : {}}
            />
            <p className="fs-1">
              I hearby agree, to let zoop.one verify my data for verification
            </p>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id={IdsGenerator({
                prefix: "passport service popup",
                id: "click",
                sufix: "run verification",
              })}
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassportServicePopup;
